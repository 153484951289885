export default [
  {
    type: "monthly",
    year: 2021,
    month: 1,
    btc: 33.7,
    source: ""
  },
  {
    type: "monthly",
    year: 2021,
    month: 2,
    btc: 35.02,
    source: "https://www.globenewswire.com/news-release/2021/03/04/2186952/0/en/Digihost-Announces-35-02-Bitcoins-Mined-in-the-Month-of-February.html"
  },
  {
    type: "monthly",
    year: 2021,
    month: 3,
    btc: 36.54,
    source: "https://www.globenewswire.com/news-release/2021/04/06/2204840/0/en/Digihost-Announces-105-26-Bitcoins-Mined-in-the-First-Quarter-of-2021-and-Provides-Operations-Update.html"
  },
  {
    type: "monthly",
    year: 2021,
    month: 4,
    btc: 37.50,
    source: "https://www.globenewswire.com/news-release/2021/05/05/2223232/0/en/Digihost-Announces-309-Bitcoins-Held-at-the-End-of-April-2021.html"
  },
  {
    type: "monthly",
    year: 2021,
    month: 5,
    btc: 34.26,
    source: ""
  },
  {
    type: "monthly",
    year: 2021,
    month: 6,
    btc: 38.19,
    source: ""
  },
  {
    type: "monthly",
    year: 2021,
    month: 7,
    btc: 51.28,
    source: "https://www.globenewswire.com/news-release/2021/08/03/2273576/0/en/Digihost-Announces-a-34-Increase-in-Month-Over-Month-Bitcoin-Production-and-Provides-Mining-Operation-Results-for-July.html"
  },
  {
    type: "monthly",
    year: 2021,
    month: 8,
    btc: 44.07,
    source: "https://www.globenewswire.com/news-release/2021/09/07/2292379/0/en/Digihost-Provides-Mining-Operation-Results-for-August-and-Announces-a-185-Increase-in-Year-Over-Year-Monthly-Bitcoin-Production.html"
  },
  {
    type: "monthly",
    year: 2021,
    month: 9,
    btc: 37.67,
    source: "https://www.globenewswire.com/en/news-release/2021/10/04/2307743/0/en/Digihost-Provides-Mining-Operation-Results-for-Q3-2021-and-Announces-a-218-Increase-in-Quarterly-Bitcoin-Production-Over-the-Previous-Years-Quarter.html"
  },
  {
    type: "monthly",
    year: 2021,
    month: 10,
    btc: 0,
    source: ""
  },
  {
    type: "monthly",
    year: 2021,
    month: 11,
    btc: 0,
    source: ""
  },
  {
    type: "monthly",
    year: 2021,
    month: 12,
    btc: 0,
    source: ""
  },
  /* Quarterly */
  {
    type: "quarterly",
    year: 2020,
    quarter: 1,
    btc: 0,
    source: ""
  },
  {
    type: "quarterly",
    year: 2020,
    quarter: 2,
    btc: 0,
    source: ""
  },
  {
    type: "quarterly",
    year: 2020,
    quarter: 3,
    btc: 0,
    source: ""
  },
  {
    type: "quarterly",
    year: 2020,
    quarter: 4,
    btc: 0,
    source: ""
  },
  {
    type: "quarterly",
    year: 2021,
    quarter: 1,
    btc: 105.26,
    source: "https://www.globenewswire.com/news-release/2021/07/06/2258084/0/en/Digihost-Announces-215-Bitcoins-Mined-in-the-First-Half-of-2021.html"
  },
  {
    type: "quarterly",
    year: 2021,
    quarter: 2,
    btc: 109.98,
    source: "https://www.globenewswire.com/news-release/2021/07/06/2258084/0/en/Digihost-Announces-215-Bitcoins-Mined-in-the-First-Half-of-2021.html"
  },
  {
    type: "quarterly",
    year: 2021,
    quarter: 3,
    btc: 133.2,
    source: "https://www.globenewswire.com/en/news-release/2021/10/04/2307743/0/en/Digihost-Provides-Mining-Operation-Results-for-Q3-2021-and-Announces-a-218-Increase-in-Quarterly-Bitcoin-Production-Over-the-Previous-Years-Quarter.html"
  },
  {
    type: "quarterly",
    year: 2021,
    quarter: 4,
    btc: 0,
    source: ""
  },
]
