<template>
  <h1>About</h1>

  <Card heading="About Heading">
    <h1>Heading 1</h1>
    <h2>Heading 2</h2>
    <h3>Heading 3</h3>
    <h4>Heading 4</h4>
    <h5>Heading 5</h5>
    <h6>Heading 6</h6>
    <p>Paragraph</p>
    <img
      src="http://placehold.it/1000x300?text=Image"
      style="width: 100%; height: 300px"
    />
    <p>
      Lorem ipsum dolor sit amet consectetur adipisicing elit. Temporibus eius,
      maiores inventore blanditiis aut labore deleniti beatae veniam
      necessitatibus sapiente facere ut. Incidunt dolorem aperiam sit, obcaecati
      maiores placeat ea? Lorem ipsum dolor sit amet consectetur adipisicing
      elit. Temporibus eius, maiores inventore blanditiis aut labore deleniti
      beatae veniam necessitatibus sapiente facere ut. Incidunt dolorem aperiam
      sit, obcaecati maiores placeat ea?
    </p>
    <p>
      Lorem ipsum dolor sit amet consectetur adipisicing elit. Temporibus eius,
      maiores inventore blanditiis aut labore deleniti beatae veniam
      necessitatibus sapiente facere ut. Incidunt dolorem aperiam sit, obcaecati
      maiores placeat ea? Lorem ipsum dolor sit amet consectetur adipisicing
      elit. Temporibus eius, maiores inventore blanditiis aut labore deleniti
      beatae veniam necessitatibus sapiente facere ut. Incidunt dolorem aperiam
      sit, obcaecati maiores placeat ea?
    </p>
    <p>
      Lorem ipsum dolor sit amet consectetur adipisicing elit. Temporibus eius,
      maiores inventore blanditiis aut labore deleniti beatae veniam
      necessitatibus sapiente facere ut. Incidunt dolorem aperiam sit, obcaecati
      maiores placeat ea? Lorem ipsum dolor sit amet consectetur adipisicing
      elit. Temporibus eius, maiores inventore blanditiis aut labore deleniti
      beatae veniam necessitatibus sapiente facere ut. Incidunt dolorem aperiam
      sit, obcaecati maiores placeat ea?
    </p>
    <p>
      Lorem ipsum dolor sit amet consectetur adipisicing elit. Temporibus eius,
      maiores inventore blanditiis aut labore deleniti beatae veniam
      necessitatibus sapiente facere ut. Incidunt dolorem aperiam sit, obcaecati
      maiores placeat ea? Lorem ipsum dolor sit amet consectetur adipisicing
      elit. Temporibus eius, maiores inventore blanditiis aut labore deleniti
      beatae veniam necessitatibus sapiente facere ut. Incidunt dolorem aperiam
      sit, obcaecati maiores placeat ea?
    </p>
  </Card>
</template>

<script>
import Card from "@/components/Card.vue";

export default {
  name: "About",
  components: {
    Card,
  },
};
</script>
